import { useRouter } from "next/router";
import React, { useEffect } from "react";
import Toggle from "react-toggle";
import { useRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { useDarkTheme } from "../../contexts/DarkTheme";
import LoginCard from "./LoginCard";
import ResetPassword from "./ResetPassword";
import TwoFactorAuth from "./TwoFactorAuth";

type AuthCardProps = {
  toast?: any;
};

const AuthCard: React.FC<AuthCardProps> = (props: AuthCardProps) => {
  const [formState, setFormState] = useRecoilState(authFormState);
  const router = useRouter();
  const theme = useDarkTheme();
  useEffect(() => {
    if (localStorage.getItem("darkTheme") == "true") {
      theme.setDarkTheme(true);
    }
  }, []);

  if (typeof window !== "undefined") {
    if (theme.darkTheme == true) {
      document.body.className = "dark";
    } else {
      document.body.classList.remove("dark");
    }
  }

  const toggleTheme = () => {
    if (localStorage.getItem("darkTheme") == "true") {
      theme.setDarkTheme(false);
      localStorage.removeItem("darkTheme");
    } else {
      theme.setDarkTheme(true);
      localStorage.setItem("darkTheme", "true");
    }
  };

  return (
    <>
      <div className="relative backdrop-blur-md p-10 text-center space-y-9 max-w-[380px] max-h-full rounded-md m-4">
        <div className="hidden dark:block">
          <img
            src="/images/darkrect.png"
            height="100%"
            width={"100%"}
            className="absolute z-9 left-0 bottom-0 h-[100%] opacity-70"
            alt=""
          />
        </div>
        <div className="dark:hidden">
          <img
            src="/images/rect.png"
            height="100%"
            width={"100%"}
            className="absolute z-9 left-0 bottom-0 h-[100%] opacity-0"
            alt=""
          />
        </div>
        <div className="relative z-99">
          {formState.view == "login" && <LoginCard />}
          {formState.view == "resetPassword" && (
            <ResetPassword toast={props.toast} />
          )}
          {/* {formState.view == 'twoFactorAuth' && <TwoFactorAuth />} */}
          {/* <div className='flex flex-row justify-center space-x-2 mt-5'>
                        <label className="form-check-label text-white font-semibold text-[13px] dark:text-white dark:opacity-80">
                            Light
                        </label>
                        <div>
                            <Toggle
                                checked={theme.darkTheme}
                                icons={false}
                                onChange={(value: any) => {
                                    toggleTheme();
                                }}
                            />
                        </div>
                        <label className="form-check-label text-white font-semibold text-[13px] dark:text-white dark:opacity-80">
                            Dark
                        </label>
                    </div> */}
        </div>
      </div>
    </>
  );
};
export default AuthCard;
