import axios from "axios";
import type { NextPage } from "next";
import { getSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { FaApple } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { authFormState } from "../Atoms/auth/AuthFormState";
import AuthCard from "../components/auth/AuthCard";
import { API_URL, AppConfig } from "../config";

const Home: NextPage = (props: any) => {
  const [formState, setFormState] = useRecoilState(authFormState);
  const router = useRouter();
  const { t } = useTranslation("common");
  let count = 0;
  useEffect(() => {
    if (props.user !== null) {
      if (localStorage && props.user.success == true) {
        let userInfo = props.user;
        localStorage.setItem("user-info", JSON.stringify(props.user));
        localStorage.setItem("userAuthenticated", "true");
        if (router.query.redirect) {
          router.push(router?.query?.redirect?.toString());
          return () => {};
        }
        if (userInfo?.login_flow_new_user == "1") {
          router.push(`/explore?locale=${userInfo?.language}`);
        } else {
          router.push(`/build?locale=${userInfo?.language}`);
        }
      }
    }
  }, []);
  const toastMessage = (status: boolean, message: string) => {
    if (status == true) {
      toast.success(message);
      return;
    } else {
      toast.error(message);
    }
    throw new Error(message);
  };
  const custom_button = (
    <div className="flex justify-center">
      <div className="flex lg:flex-row flex-col gap-4 mt-4">
        <a
          target="_blank"
          rel="noreferrer"
          className="button flex items-center font-semibold border border-white"
          href="https://apps.apple.com/in/app/bytemine/id1568477262"
        >
          <FaApple className="mr-2" size={35} />
          <span className="flex flex-col  text-2xl">
            <span className="text-xs font-normal">
              {t("index.availableOnThe")}
            </span>
            {t("index.appStore")}
          </span>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className="button flex items-center font-semibold border border-white"
          href="https://play.google.com/store/apps/details?id=com.bytemine&hl=en_IN&gl=US"
        >
          <Image src="/images/playstore.png" height={30} width={30} alt="" />
          <span className="flex flex-col ml-2 text-2xl">
            <span className="text-xs font-normal">{t("index.getItOn")}</span>
            {t("index.googlePlay")}
          </span>
        </a>
      </div>
    </div>
  );
  return (
    <>
      <Head>
        <meta name="robots" content="index,follow" />
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${router.basePath}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`${router.basePath}/site.webmanifest`} />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
        {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" /> */}
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          href="https://rsms.me/inter/inter.css"
          key="inter-font"
        />
      </Head>
      <NextSeo
        title="Sign in - Market Dynamics"
        description="Sign in to the Market Dynamics Web Application"
        openGraph={{
          locale: AppConfig.locale,
          site_name: "Trade Smarter with Market Dynamics",
        }}
      />
      <Toaster position="top-right" />
      <div className="bg-gradient-to-r from-[#8E5FF1] to-[#68C9FF] dark:from-[#121212] dark:to-[#313131]">
        <div className="relative flex flex-col md:flex-row bg-no-repeat bg-golden-hand bg-contain">
          <img
            className="absolute top-0 right-[10%]"
            src="/images/silver-hand.png"
            height={"619"}
            width={"521"}
            alt=""
          />
          <div className="hidden lg:block lg:w-1/2">
            <div className="flex flex-col object-contain h-full items-center justify-center ">
              {formState.view === "twoFactorAuth" ? (
                <Image
                  src={"/images/appqr.png"}
                  width={300}
                  height={300}
                  priority={true}
                  alt=""
                />
              ) : (
                <>
                  <div className="text-white text-5xl max-w-[350px] text-center font-bold mb-7">
                    <h2>Sign in to Market Dynamics</h2>
                  </div>
                  <p className="text-white text-xl mb-5">
                    {t("index.tradeFromAnywhere")}
                  </p>
                  <p className="text-white max-w-[350px] text-center mb-5">
                    {t("index.performance")}
                  </p>
                </>
              )}
              <div>
                {/* <h3 className='text-center font-normal dark:text-white dark:opacity-80'>Trade smarter from anywhere</h3> */}
                {custom_button}
              </div>
            </div>
          </div>
          <div className="h-screen flex flex-col w-full lg:w-1/2 justify-center items-center">
            <AuthCard toast={toastMessage} />
            <div className="block lg:hidden lg:w-1/2">
              <div className="text-white text-5xl max-w-[350px] text-center font-bold mb-7">
                {t("index.downloadApp")}
              </div>
              {custom_button}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Home.getInitialProps = async (ctx) => {
//     let session:any = await getSession(ctx);
//     let res = null;
//     if(session){
//         res = await axios.post(API_URL+'/social_login',{email:session?.user?.email})
//         res = res.data;
//     }
// const json = await res.json()
//     return { user: res }
// }

export const getServerSideProps = async (ctx: any) => {
  let session: any = await getSession(ctx);
  let res = null;
  if (session) {
    res = await axios.post(API_URL + "/social_login", {
      email: session?.user?.email,
    });
    res = res.data;
  }
  return {
    props: {
      user: res,
      ...(await serverSideTranslations(ctx.query.locale || "en", ["common"])),
    },
  };
};

export default Home;
