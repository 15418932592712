import { atom } from "recoil";

export interface AuthFormState {
  view: "login" | "resetPassword" | "twoFactorAuth";
}

const defaultAuthState: AuthFormState = {
  view: "login",
};

export const authFormState = atom({
  key: "authFormState",
  default: defaultAuthState,
});
